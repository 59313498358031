<script setup>
import { ref } from 'vue';
import {
    EyeIcon,
    EyeSlashIcon
} from '@heroicons/vue/24/solid';
import { loginWithFirebase, getCustomerDetails, saveCustomerSession, generateFingerPrint } from "../rules";

const props = defineProps({
    completeLoginFlow: Function,
    isZiplyCustomer: Boolean,
    foundCustomerSession: Boolean,
    isExistingCustomer: Boolean,
    loginLoading: Object,
    newCustomer: Function,
    hashKey: String
})

const resetPasswordUrl = import.meta.env.VITE_RESET_PASSWORD_URL;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S{8,}$/;
const isLoading = ref(false);
const showPass = ref(false);
const username = ref('');
const password = ref('');
const formErrors = ref({
    credentials: '',
    username: '',
    password: ''
});
const apiError = ref('');

const toggleShowPass = () => {
    showPass.value = !showPass.value;
};

const extractCustomerDetails = (customerDetailsRes) => {
    const customerDetails = customerDetailsRes?.data?.data?.customerContact;
    const { givenName, familyName } = customerDetails?.givenAndFamilyName;
    const { telephoneNumber } = customerDetails?.telephoneContact[0];
    const { emailAddress } = customerDetails?.emailContact[0];
    const { accountNumber, usi } = customerDetails?.linkedAccountNumbers[0];
    // TODO: Add Billling USI
    return {
        email: emailAddress,
        firstName: givenName,
        lastName: familyName,
        phone: telephoneNumber,
        usi: usi
    };
};

const validateForm = async () => {
    isLoading.value = true;
    if (username.value.length === 0) {
        formErrors.value.username = 'Please enter your email.';
        isLoading.value = false;
        return;
    } else if (username.value.length !== 0 && !emailPattern.test(username.value)) {
        formErrors.value.username = 'Please enter a valid email.';
        isLoading.value = false;
        return;
    }

    if (password.value.length === 0) {
        formErrors.value.password = 'Please enter your password.';
        isLoading.value = false;
        return;
    }
    else if (password.value.length !== 0 && !passwordPattern.test(password.value)) {
        formErrors.value.password = 'Please enter the correct password'
        isLoading.value = false;
        return;
    }
    // Reset input errors
    formErrors.value.username = '';
    formErrors.value.password = '';
    // Call login API
    const response = await loginWithFirebase(username.value, password.value);
    if (!response.success) {
        isLoading.value = false;
        apiError.value = response.error || 'Error logging in';
        return;
    } else {
        if (response.data.error) {
            formErrors.value.credentials = response.data.error.errors[0].message;
            isLoading.value = false;
            return;
        } else {
            formErrors.value.credentials = ""
        }
        const { idToken, refreshToken } = response.data;
        // my account API
        const customerDetailsRes = await getCustomerDetails(username.value, idToken);
        if (!customerDetailsRes.success) {
            apiError.value = customerDetailsRes.error || 'Error getting customer details';
            isLoading.value = false;
            return;
        } else if (customerDetailsRes?.success && customerDetailsRes.data.errors) {
            apiError.value = customerDetailsRes.data.errors[0].message || 'Error getting customer details';
            isLoading.value = false;
            return;
        } else {
            const customerDetails = extractCustomerDetails(customerDetailsRes);
            const fingerPrint = await generateFingerPrint();
            const customerSession = {
                ...customerDetails,
                uuidf: fingerPrint,
                uuidt: idToken ?? null,
                uuidr: refreshToken ?? null
            };
            if (props.isZiplyCustomer && props.foundCustomerSession || props.isExistingCustomer) {
                await saveCustomerSession(customerSession, props.hashKey);
                props.completeLoginFlow();
            }
            setTimeout(() => {
                isLoading.value = false;
            }, 10000);
        }
    }
};

</script>

<template>
    <div class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center zf-w-full">
        <img src="../assets/ziplyLogo.png" alt="Ziply Logo" class="zf-mb-4">
        <h1 class="!zf-text-xl">Please Login</h1>
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.credentials !== ''">
            {{ formErrors.credentials }}
        </p>
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-2 zf-text-center" v-if="apiError !== ''">
            {{ apiError }}
        </p>
        <form method="post" @submit.prevent="validateForm()" class="zf-flex zf-flex-col zf-w-full zf-p-5 ">
            <div>
                <div class="zf-flex zf-flex-col">
                    <input type="email" name="email" placeholder="Email" v-model.trim="username"
                        @keydown="formErrors.username = ''"
                        class="!zf-w-full !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12 zf-border-2 zf-outline-none zf-rounded-lg" />
                </div>
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.username !== ''">
                    {{ formErrors.username }}
                </p>
                <div class="zf-flex zf-flex-col zf-relative">
                    <input :type="showPass ? 'text' : 'password'" name="password" placeholder="Password"
                        v-model.trim="password" @keydown="formErrors.username = ''"
                        class="!zf-w-full !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12 zf-border-2 zf-outline-none zf-rounded-lg" />
                    <EyeIcon class="!zf-w-6 !zf-h-6 !zf-absolute !zf-right-3 !zf-top-3" @click="toggleShowPass"
                        v-if="showPass" />
                    <EyeSlashIcon class="!zf-w-6 !zf-h-6 !zf-absolute !zf-right-3 !zf-top-3 " @click="toggleShowPass"
                        v-if="!showPass" />
                </div>
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.password !== ''">
                    {{ formErrors.password }}
                </p>
            </div>
            <!-- <input v-if="isLoading" type="submit" value="Login" :disabled="isLoading"
                class="!zf-px-4 !zf-py-2 !zf-m-1 zf-rounded-full zf-font-bold zf-border-ziply-blue !zf-border-2 zf-text-ziply-blue hover:!zf-bg-ziply-blue hover:!zf-text-white !zf-uppercase"
                :class="{
            'zf-opacity-50 zf-cursor-not-allowed': isLoading,
        }" /> -->
            <button type="submit" @click="login" :disabled="isLoading"
                class="!zf-px-4 !zf-py-2 !zf-m-1 !zf-flex !zf-justify-center zf-rounded-full zf-font-bold zf-border-ziply-blue !zf-border-2 zf-text-ziply-blue hover:!zf-bg-ziply-blue hover:!zf-text-white !zf-uppercase"
                :class="{
                    'zf-opacity-50 zf-cursor-not-allowed': isLoading
                }">
                <span v-if="!isLoading">Login</span>
                <span v-else>
                    <svg class="zf-animate-spin zf-h-5 zf-w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle class="zf-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="zf-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.5A9.5 9.5 0 002.5 12H4z">
                        </path>
                    </svg>
                </span>
            </button>
        </form>
        <div>
            <a class="zf-link zf-cursor-pointer !zf-mb-2 !zf-font-bold" :href="resetPasswordUrl" target="_blank">
                Forgot password?
            </a>
        </div>
        <div @click="newCustomer()">
            <a class="zf-link zf-cursor-pointer !zf-mb-2 !zf-font-bold">
                I am a new customer or unable to login
            </a>
        </div>
    </div>
</template>